<template>
  <div>
    <slot v-bind="{ image, handleOpen }">
      <div class="w-full flex items-center justify-center relative overflow-hidden h-48 border rounded">
        <div class="h-full w-full absolute inset-0 blur">
          <img v-if="image" :src="image" class="object-cover object-center w-full h-full scale-101">
        </div>

        <button class="z-10 px-3 py-2 rounded-md bg-blue-700 text-white hover:bg-blue-500 transition-all flex items-center space-x-2" @click.stop.prevent="handleOpen">
          <span>Tap/click to edit</span>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
          </svg>
        </button>
      </div>
    </slot>

    <component :is="portal ? 'portal' : 'div'" :to="portal">
      <div
        v-if="active" class="h-[100dvh] w-screen fixed inset-0 bg-gray-100 select-none"
        style="z-index: 100000; touch-action: pan-x pan-y;"
      >
        <!-- Controls -->
        <div class="w-full max-w-xs z-20 absolute top-0 left-1/2 -translate-x-1/2 px-4 my-4 sm:my-8 flex items-center justify-between">
          <div class="flex items-center space-x-1">
            <button
              class="h-9 w-9 rounded-full bg-gray-900 text-white flex items-center justify-center"
              :class="!_value || _value.layers.length === 0 ? 'opacity-70' : ''"
              @click="handleUndo"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
              </svg>
            </button>

            <button
              class="h-9 w-9 rounded-full bg-gray-900 text-white flex items-center justify-center"
              :class="state.redo.length === 0 ? 'opacity-70' : ''"
              @click="handleRedo"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3" />
              </svg>
            </button>

            <div class="w-4" />

            <button
              class="h-9 w-9 rounded-full bg-gray-900 flex items-center justify-center"
              :class="panEnabled ? 'text-blue-600' : 'text-white'"
              @click="handleTogglePan"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002" />
              </svg>
            </button>
          </div>

          <div class="flex items-center space-x-2">
            <button
              class="h-9 rounded-xl bg-blue-700 text-white flex items-center justify-center px-3 font-semibold space-x-2"
              @click="handleSave"
            >
              <span class="mt-px">Done</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>

            </button>
          </div>
        </div>

        <!-- Tools -->
        <div v-if="tools" class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 my-4 sm:my-8 flex flex-col items-center">
          <div v-if="tools[tool] && tools[tool].showSettings" class="bg-gray-900 rounded-xl shadow-sm p-3 inline-flex items-center space-x-2 w-auto mx-auto mb-3">
            <div v-for="(setting, key) in tools[tool].settings" :key="key">
              <component :is="setting.component" />
            </div>
          </div>

          <div v-if="Object.keys(tools).length > 1" class="bg-gray-900 rounded-xl shadow-sm p-3 flex items-center space-x-4">
            <button
              v-for="(item, key) in tools"
              :key="key"
              :class="[
                'h-8 w-8 rounded-full flex items-center justify-center',
                tool === key && !panEnabled ? 'text-blue-600' : 'text-white'
              ]"
              @click="handleSelectTool(key)"
            >
              <span v-html="item.icon" />
            </button>
          </div>
        </div>


        <div
          ref="panzoom"
          :class="ready ? 'opacity-100' : 'opacity-0'"
          :style="{
            height: _height,
            width: _width,
            maxHeight: _height,
            maxWidth: _width,
            minHeight: _height,
            minWidth: _width
          }"
        >
          <div
            class="relative transition-all duration-300 ease-in-out"
            :style="styles"
          >
            <img
              ref="background"
              :src="image"
              class="h-full w-full absolute inset-0 object-contain"

              @load="initCanvas"
            >

            <canvas
              ref="canvas"
              class="w-full h-full absolute inset-0"
              :class="[
                panEnabled ? 'cursor-grab pointer-events-none': 'cursor-none'
              ]"
              :height="_height"
              :width="_width"
              :style="styles"
              @pointerdown="handlePointerDown"
              @pointermove="handlePointerMove"
              @pointerup="handlePointerUp"

              @touchstart="handleTouchStart"
              @touchmove="handleTouchMove"
              @touchend="handleTouchEnd"
            />

            <canvas
              ref="canvas_cursor"
              :height="_height"
              :width="_width"
              :style="styles"
              class="w-full h-full absolute inset-0 z-10 pointer-events-none"
            />
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import panzoom from 'panzoom'

import Brush from './tools/Brush'
import Eraser from './tools/Eraser'
import Stamp from './tools/Stamp'

export default {
  name: 'OvatuAnnotate',
  props: {
    value: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => {
        return {
          allowHistory: false,
          tools: {
            brush: {
              size: 4,
              colours: [
                'black',
                '#dc2626',
                '#0284c7',
                '#059669'
              ]
            },
            eraser: {
              size: 10
            },
            stamp: {
              size: 20,
              stamps: [
                '𝟭',
                '𝟮',
                '𝟯'
              ]
            }
          }
        }
      }
    },
    portal: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      active: false,
      ready: false,

      tool: null,
      tools: null,

      panzoom: null,
      panEnabled: false,

      mouse: {
        x: 0,
        y: 0
      },
      cursor: {
        lastX: 0,
        lastY: 0
      },
      state: {
        redo: []
      }
    }
  },
  computed: {
    _value: {
      get () {
        return this.value?.length ? JSON.parse(this.value) : null
      },
      set (value) {
        this.$emit('input', JSON.stringify(value))
      }
    },
    _width () {
      return this._value?.width + 'px' || ''
    },
    _height () {
      return this._value?.height + 'px' || ''
    },
    styles () {
      const { _height, _width } = this

      return {
        height: _height,
        width: _width,
        maxHeight: _height,
        maxWidth: _width,
        minHeight: _height,
        minWidth: _width
      }
    }
  },
  watch: {
    panEnabled (value) {
      if (value) {
        this.panzoom?.resume()
      } else {
        this.panzoom?.pause()
      }
    }
  },
  methods: {
    /* Open the annotation window */
    handleOpen () {
      this.ready = false
      this.active = true
    },

    initCanvas (e) {
      this.$nextTick(() => {
        const background = this.$refs.background

        const height = background.naturalHeight
        const width = background.naturalWidth

        if (!this._value) {
          this._value = {
            layers: [],
            width: width,
            height: height,
            format: 'annotation',
            version: 1
          }
        }

        setTimeout(() => {
          const that = this

          const context = {
            canvas: this.$refs.canvas,
            canvas_cursor: this.$refs.canvas_cursor,
            mouse: this.mouse,
            cursor: this.cursor,
            get layers () {
              return that._value.layers
            },
            set layers (value) {
              that._value = {
                ...that._value,
                layers: value
              }
            }
          }

          this.tools = {}

          if (this.config.draw?.enabled) {
            this.tools.brush = new Brush(context, this.config.draw)

            if (!this.tool) {
              this.tool = 'brush'
            }
          }

          if (this.config.erase?.enabled) {
            this.tools.eraser = new Eraser(context, this.config.erase)

            if (!this.tool) {
              this.tool = 'eraser'
            }
          }

          if (this.config.stamps?.enabled) {
            this.tools.stamp = new Stamp(context, this.config.stamps)

            if (!this.tool) {
              this.tool = 'stamp'
            }
          }

          requestAnimationFrame(this.renderCursor)

          if (this._value && this._value.layers.length > 0) {
            for (const layer of this._value.layers) {
              if (layer.type === 'line') {
                this.tools.brush.redraw(layer)
              } else if (layer.type === 'eraser') {
                this.tools.eraser.redraw(layer)
              } else if (layer.type === 'stamp') {
                this.tools.stamp.redraw(layer)
              }
            }
          }

          this.initPanzoom();
        }, 500);
      })
    },

    initPanzoom () {
      this.$nextTick(() => {
        const element = this.$refs.panzoom

        this.panzoom = panzoom(element, {
          autocenter: true,
          beforeMouseDown: (e) => {
            return !this.panEnabled
          },
          onTouch: (e) => {
            return false
          }
        })

        this.panzoom.pause()

        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        const zoom = 0.7

        this.panzoom.setTransformOrigin({x: 0.5, y: 0.5})
        this.panzoom.zoomTo(windowWidth / 2, windowHeight / 2, zoom);

        this.panzoom.setTransformOrigin(null)

        this.ready = true
      })
    },

    destroyPanzoom () {
      this.panzoom?.dispose()
    },

    handleTogglePan () {
      this.panEnabled = !this.panEnabled
    },

    handleSelectTool (tool) {
      this.tool = tool
      this.panEnabled = false
    },

    /* Set the current state to the previous item in the history stack */
    handleUndo () {
      if (this._value.layers.length > 0) {
        // Remove last layer and push to redo stack
        const layer = this._value.layers.pop()
        this.state.redo.push(layer)

        // Clear canvas
        const canvas = this.$refs.canvas
        const ctx = canvas.getContext('2d')

        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Redraw other layers
        if (this._value.layers.length > 0) {
          for (const layer of this._value.layers) {
            if (layer.type === 'line') {
              this.tools.brush.redraw(layer)
            } else if (layer.type === 'eraser') {
              this.tools.eraser.redraw(layer)
            } else if (layer.type === 'stamp') {
              this.tools.stamp.redraw(layer)
            }
          }
        }
      }
    },

    /* Set the current state to the next item in the history stack */
    handleRedo () {
      if (this.state.redo.length > 0) {
        const layer = this.state.redo.pop()

        this._value.layers.push(layer)

        if (layer.type === 'line') {
          this.tools.brush.redraw(layer)
        } else if (layer.type === 'eraser') {
          this.tools.eraser.redraw(layer)
        } else if (layer.type === 'stamp') {
          this.tools.stamp.redraw(layer)
        }
      }
    },

    /* Clear all contents of the canvas */
    handleClear () {
      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d')

      ctx.clearRect(0, 0, canvas.width, canvas.height)
    },

    /* Save the current state of the canvas to a dataURL */
    handleSave () {
      console.log(this.value)

      this.active = false
    },

    /* Render the cursor on a seperate cursor canvas if the current tool includes a cursor function */
    renderCursor () {
      const tool = this.tools[this.tool]

      if (tool?.drawCursor) {
        tool.drawCursor()
      }

      requestAnimationFrame(this.renderCursor)
    },

    /* On pointer down, call pointerDown function on current tool if included */
    handlePointerDown (e) {
      if (e.pointerType !== 'touch') {
        const x = e.offsetX
        const y = e.offsetY

        const tool = this.tools[this.tool]

        if (tool?.pointerDown) {
          tool.pointerDown({ x, y })
        }
      }
    },

    /* On pointer move, set the current mouse position then call pointerMove function on current tool if included */
    handlePointerMove (e) {
      if (e.pointerType !== 'touch') {
        const x = e.offsetX
        const y = e.offsetY

        this.mouse.x = x
        this.mouse.y = y

        const tool = this.tools[this.tool]

        if (tool?.pointerMove) {
          tool.pointerMove({ x, y })
        }
      }
    },

    /* On pointer up, push the current canvas state to history and call the pointerUp function on current tool if included */
    handlePointerUp (e) {
      if (e.pointerType !== 'touch') {
        const x = e.offsetX
        const y = e.offsetY

        const tool = this.tools[this.tool]

        if (tool?.pointerUp) {
          tool.pointerUp({ x, y })
        }
      }
    },

    /* On touch start, call touchStart function on current tool if included */
    handleTouchStart (e) {
      const tool = this.tools[this.tool]

      const rect = e.target.getBoundingClientRect()
      const transform = this.panzoom.getTransform()
      const scale = transform.scale

      const x = (e.targetTouches[0].pageX - rect.left) / scale
      const y = (e.targetTouches[0].pageY - rect.top) / scale

      if (tool?.pointerDown) {
        tool.pointerDown({ x, y })
      }
    },

    /* On touch move, set the current mouse position then call pointerMove function on current tool if included */
    handleTouchMove (e) {
      const tool = this.tools[this.tool]

      const rect = e.target.getBoundingClientRect()
      const transform = this.panzoom.getTransform()
      const scale = transform.scale

      const x = (e.targetTouches[0].pageX - rect.left) / scale
      const y = (e.targetTouches[0].pageY - rect.top) / scale

      this.mouse.x = x
      this.mouse.y = y

      if (tool?.pointerMove) {
        tool.pointerMove({ x, y })
      }
    },

    /* On touch end, push the current canvas state to history and call the pointerUp function on current tool if included */
    handleTouchEnd (e) {
      const tool = this.tools[this.tool]

      if (tool?.pointerUp) {
        tool.pointerUp()
      }
    },

    lerp (a, b, n) {
      return (1 - n) * a + n * b
    }
  }
}
</script>

<style>
/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none)  {
    html {
      min-height: 100.3%;
      overscroll-behavior-y: none;
    }
  }
}
/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none))  {
    html {
      height: 100%;
      overflow: hidden;
    }
    body {
      margin: 0px;
      max-height: 100%; /* or `height: calc(100% - 16px);` if body has default margin */
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    /* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
  }
}

/* prevent pull-to-refresh for Chrome 63+ */
body{
  overscroll-behavior-y: none;
}
</style>
