var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{ref:"validation",attrs:{"rules":_vm.rules,"name":_vm.name}},[(_vm.date)?_c('v-date-picker',{ref:"calendar",staticClass:"hidden",attrs:{"value":null}}):_vm._e(),_vm._v(" "),_c('div',{class:{
      'flex items-start space-x-3' : _vm.col,
      'flex-row-reverse justify-end space-x-3 space-x-reverse' : _vm.col & _vm.reverse,
    }},[_c('div',{class:{
        // col && !reverse ? 'w-3/12' : 'w-full'
        'w-full' : !_vm.col,
        'w-3/12' : _vm.col && !_vm.reverse,
      }},[_c('div',{staticClass:"w-full flex justify-between items-center"},[(_vm.label)?_c('label',{class:_vm.labelClass,attrs:{"tabindex":"-1"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.label)}}),_vm._v(" "),(_vm.isRequired)?_c('o-tooltip',{staticClass:"inline-block",attrs:{"text":"Required field"}},[_c('span',{staticClass:"text-sm text-red-400 dark:text-red-600"},[_vm._v("\n              *\n            ")])]):_vm._e(),_vm._v(" "),(_vm.tooltip)?_c('o-tooltip',{staticClass:"inline-block translate-y-[0.2rem]",attrs:{"text":_vm.tooltip}},[_c('div',{staticClass:"h-5 w-5 rounded-full text-blue-500 flex items-center justify-center"},[_c('o-icon',{attrs:{"icon":"info"}})],1)]):_vm._e()],1):_vm._e(),_vm._v(" "),_vm._t("control",function(){return [(_vm.modified)?_c('o-tooltip',{attrs:{"text":_vm.modifiedMessage}},[_c('o-icon',{staticClass:"text-yellow-600 mx-1 dark:text-yellow-400",attrs:{"icon":"warningBold","fill":""}})],1):_vm._e()]})],2),_vm._v(" "),(_vm.info)?_c('p',{class:_vm.infoClass,domProps:{"innerHTML":_vm._s(_vm.info)}}):_vm._e()]),_vm._v(" "),_c('div',{class:{
        // col && !reverse ? 'w-3/12' : 'w-full'
        'w-full' : !_vm.col,
        'w-9/12' : _vm.col && !_vm.reverse,
      }},[_c('div',{ref:"fields",class:[{ 'mt-1' : _vm.label || _vm.info }]},[_vm._t("default",null,null,_vm.slotProps)],2),_vm._v(" "),(_vm.message || (_vm.isError && _vm.errorMessage) || (_vm.isSuccess && _vm.successMessage))?_c('div',{class:_vm.statusClasses},[_c('o-icon',{staticClass:"ml-1 mr-2",attrs:{"icon":_vm.icon,"size":14}}),_vm._v(" "),_c('p',{staticClass:"leading-none pt-px",domProps:{"innerHTML":_vm._s(_vm.errorMessage || _vm.message || _vm.successMessage)}})],1):(_vm.date && _vm.dateFormat)?_c('div',[_c('p',{staticClass:"text-sm opacity-80 mt-1"},[_vm._v("\n          "+_vm._s(_vm.dateFormat)+"\n        ")])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }