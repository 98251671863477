var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col justify-between h-full fixed z-40 sm:z-20 sm:relative transition-all",class:[
    _vm.collapse ? 'w-60 sm:w-16' : 'min-w-60 w-60',
    _vm.mobile ? 'left-0' : '-left-72 sm:left-0'
  ]},[_c('div',{class:[
      'h-full dark:border-r dark:border-gray-800 sm:dark:border-none',
      _vm.collapse ? 'w-60 sm:w-20 sm:pr-4' : 'min-w-64 w-64 sm:pr-4',
      _vm.$auth.theme.light.gradient && _vm.$colorMode.value === 'light' ? _vm.$auth.theme.light.gradient : `bg-primary-${_vm.$auth.theme.light.shade}`,
      _vm.$auth.theme.dark.gradient ? _vm.$auth.theme.dark.gradient : `dark:bg-gray-900`,
    ]},[_c('o-scroll',[_c('div',{staticClass:"flex flex-col justify-between h-full"},[_c('div',[_c('div',{staticClass:"p-4 flex items-center justify-between",class:[ _vm.collapse ? 'sm:flex-col sm:items-center' : '']},[_c('img',{staticClass:"block h-6 sm:h-8 opacity-85 -ml-1",class:[ _vm.collapse ? 'sm:hidden' : ''],attrs:{"src":require("assets/images/logo/logo-white-full-hat.png")}}),_vm._v(" "),_c('img',{staticClass:"block w-6 sm:w-8 opacity-85 mb-6",class:[ _vm.collapse ? 'hidden sm:block' : 'hidden' ],attrs:{"src":require("assets/images/logo/logo-white-icon-hat.png")}}),_vm._v(" "),_c('button',{staticClass:"hidden sm:flex opacity-85 hover:bg-white hover:bg-opacity-15 p-2 rounded-md transition-all",class:[ _vm.collapse && !_vm.mobile ? 'ml-px' : '0' ],on:{"click":_vm.toggleMenu}},[_c('o-icon',{staticClass:"text-white opacity-75",attrs:{"icon":"menuHamburger","size":18}})],1),_vm._v(" "),_c('button',{staticClass:"flex sm:hidden opacity-85 hover:bg-white hover:bg-opacity-15 p-2 rounded-md transition-all",class:[ _vm.collapse && !_vm.mobile ? 'ml-1' : '0' ],on:{"click":_vm.toggleMobile}},[_c('o-icon',{staticClass:"text-white opacity-75",attrs:{"icon":"x","size":18}})],1)]),_vm._v(" "),_c('div',{staticClass:"px-4 pt-2 pb-0"},[_c('div',{staticClass:"font-medium rounded-lg bg-white/10 dark:bg-gray-800 flex items-center text-white text-base cursor-pointer hover:bg-white/15 hover:text-secondary transition leading-none border border-white/20",class:[
                _vm.collapse && !_vm.mobile ? '-ml-px py-3 px-3 sm:p-0 sm:h-8 sm:w-9 sm:justify-center' : 'py-2 px-1',
              ],on:{"click":_vm.toggleSearch}},[_c('o-icon',{class:[
                  _vm.collapse && !_vm.mobile ? '' : 'mr-3 ml-1',
                ],attrs:{"icon":"search","size":14}}),_vm._v(" "),_c('span',{class:[
                  _vm.collapse && !_vm.mobile ? 'sm:hidden' : '',
                  'font-medium'
                ]},[_vm._v("\n                Search\n              ")])],1)]),_vm._v(" "),_c('ul',{staticClass:"flex flex-col w-full p-4 pt-3"},[_vm._l((_vm.items.primary),function(item){return [_c('item',{key:item.slug,attrs:{"item":item}})]})],2)]),_vm._v(" "),_c('div',[_c('ul',{staticClass:"flex flex-col w-full p-4"},[_c('christmas'),_vm._v(" "),_c('alerts'),_vm._v(" "),_vm._l((_vm.items.secondary),function(item){return [_c('item',{key:item.slug,attrs:{"item":item}})]}),_vm._v(" "),(!_vm.demo)?_c('support'):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"w-full text-white opacity-85 px-4 py-2 border-t border-opacity-10 border-white"},[_c('account')],1)])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }