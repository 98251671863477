export default class Tool {
  constructor (context) {
    const { canvas, canvas_cursor, cursor, mouse, value } = context
    this.canvas = canvas
    this.canvas_cursor = canvas_cursor
    this.cursor = cursor
    this.mouse = mouse
    this.value = value

    this.context = context
  }

  lerp (a, b, n) {
    return (1 - n) * a + n * b
  }
}

export class ToolSetting {

  constructor ({ value, options, template }) {
    this.value = value
    this.options = options
    this.template = template
  }

  get component () {
    const that = this

    return {
      data () {
        return {
          value: that.value,
          options: that.options
        }
      },
      template: that.template,
      methods: {
        set (val) {
          this.value = val
          that.value = val
        }
      }
    }
  }

  get showSettings () {
    return false
  }
}
