<template>
  <div v-if="update" class="h-screen w-screen absolute inset-0 bg-black/25 flex items-center justify-center z-80000">
    <div class="w-full max-h-screen max-w-md bg-white dark:bg-gray-900 rounded-xl overflow-hidden relative flex flex-col">
      <div
        v-if="update.imageUrls"
        class="w-full flex-shrink bg-cover h-100 bg-center"
        :style="{
          backgroundImage: `url(${update.imageUrls.large})`
        }"
      />

      <button class="bg-white bg-opacity-15 rounded-full flex items-center justify-center h-8 w-8 absolute top-0 right-0 m-3 text-white hover:bg-opacity-25" @click="handleDismiss">
        <o-icon icon="x" />
      </button>

      <div class="p-4 text-center">
        <h3 class="font-medium text-lg opacity-90 mb-2">
          {{ update.subject }}
        </h3>
        <p class="opacity-70">
          {{ update.header }}
        </p>
      </div>

      <div class="flex items-center justify-center space-x-2 p-4">
        <o-button variant="info" @click="handleOpen">
          Read more
        </o-button>

        <o-button outline @click="update = null">
          Remind me later
        </o-button>

        <o-button outline @click="handleDismiss">
          Dismiss
        </o-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from '~/api'

export default {
  data () {
    return {
      update: null
    }
  },
  mounted () {
    this.fetchUnreadUpdates()
  },
  methods: {
    async fetchUnreadUpdates () {
      try {
        const updates = await API.Update.unread()

        if (updates?.items?.[0]) {
          const update = updates?.items?.[0]
          this.update = update
        }
      } catch (e) {
        console.log(e)
      }
    },
    handleOpen () {
      const update = this.update
      const url = `https://ovatu.com/au/updates/${update.slug}`

      window.open(url, '_blank')

      this.handleDismiss()
    },
    async handleDismiss () {
      this.update = null

      try {
        const success = await API.Update.markAsRead()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
