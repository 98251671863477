<template>
  <div
    class="flex flex-col justify-between h-full fixed z-40 sm:z-20 sm:relative transition-all"
    :class="[
      collapse ? 'w-60 sm:w-16' : 'min-w-60 w-60',
      mobile ? 'left-0' : '-left-72 sm:left-0'
    ]"
  >
    <div
      :class="[
        'h-full dark:border-r dark:border-gray-800 sm:dark:border-none',
        collapse ? 'w-60 sm:w-20 sm:pr-4' : 'min-w-64 w-64 sm:pr-4',
        $auth.theme.light.gradient && $colorMode.value === 'light' ? $auth.theme.light.gradient : `bg-primary-${$auth.theme.light.shade}`,
        $auth.theme.dark.gradient ? $auth.theme.dark.gradient : `dark:bg-gray-900`,
      ]"
    >
      <o-scroll>
        <div class="flex flex-col justify-between h-full">
          <div>
            <div class="p-4 flex items-center justify-between" :class="[ collapse ? 'sm:flex-col sm:items-center' : '']">
              <img
                :class="[ collapse ? 'sm:hidden' : '']"
                src="~/assets/images/logo/logo-white-full-hat.png"
                class="block h-6 sm:h-8 opacity-85 -ml-1"
              >

              <img
                :class="[ collapse ? 'hidden sm:block' : 'hidden' ]"
                src="~/assets/images/logo/logo-white-icon-hat.png"
                class="block w-6 sm:w-8 opacity-85 mb-6"
              >

              <button
                class="hidden sm:flex opacity-85 hover:bg-white hover:bg-opacity-15 p-2 rounded-md transition-all"
                :class="[ collapse && !mobile ? 'ml-px' : '0' ]"
                @click="toggleMenu"
              >
                <o-icon icon="menuHamburger" :size="18" class="text-white opacity-75" />
              </button>

              <button
                class="flex sm:hidden opacity-85 hover:bg-white hover:bg-opacity-15 p-2 rounded-md transition-all"
                :class="[ collapse && !mobile ? 'ml-1' : '0' ]"
                @click="toggleMobile"
              >
                <o-icon icon="x" :size="18" class="text-white opacity-75" />
              </button>
            </div>

            <div class="px-4 pt-2 pb-0">
              <div
                class="font-medium rounded-lg bg-white/10 dark:bg-gray-800 flex items-center text-white text-base cursor-pointer hover:bg-white/15 hover:text-secondary transition leading-none border border-white/20"
                :class="[
                  collapse && !mobile ? '-ml-px py-3 px-3 sm:p-0 sm:h-8 sm:w-9 sm:justify-center' : 'py-2 px-1',
                ]"
                @click="toggleSearch"
              >
                <o-icon
                  icon="search"
                  :size="14"
                  :class="[
                    collapse && !mobile ? '' : 'mr-3 ml-1',
                  ]"
                />
                <span
                  :class="[
                    collapse && !mobile ? 'sm:hidden' : '',
                    'font-medium'
                  ]"
                >
                  Search
                </span>
              </div>
            </div>

            <ul class="flex flex-col w-full p-4 pt-3">
              <template v-for="item in items.primary">
                <item
                  :key="item.slug"
                  :item="item"
                />
              </template>
            </ul>
          </div>

          <div>
            <ul class="flex flex-col w-full p-4">
              <christmas />
              <alerts />

              <template v-for="item in items.secondary">
                <item
                  :key="item.slug"
                  :item="item"
                />
              </template>

              <support v-if="!demo" />
            </ul>

            <div class="w-full text-white opacity-85 px-4 py-2 border-t border-opacity-10 border-white">
              <account />
            </div>
          </div>
        </div>
      </o-scroll>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Item from './MenuItem.vue'
import items from './pages.js'

import Account from './Account'
import Alerts from './Alerts'
import Christmas from './Christmas'
import Support from './Support'

export default {
  components: {
    Item,
    Account,
    Alerts,
    Christmas,
    Support
  },
  data () {
    return {
      items
    }
  },
  computed: {
    ...mapGetters({
      collapse: 'menu',
      mobile: 'mobile',
      search: 'search'
    }),
    activeUserInfo () {
      return this.$auth.info
    },
    primary () {
      const primary = items.primary

      return primary.filter((item) => {
        const grants = item.grants

        if (!grants) {
          return true
        }

        return Object.entries(grants)
          .every(([model, actions]) => {
            return this.$grants.get({ model, actions })
          })
      })
    },
    secondary () {
      const secondary = items.secondary

      return secondary.filter((item) => {
        const grants = item.grants

        if (!grants) {
          return true
        }

        return Object.entries(grants)
          .every(([model, actions]) => {
            return this.$grants.get({ model, actions })
          })
      })
    },
    account () {
      return this.$auth.info
    },
    dev () {
      return this.$config.instance === 'dev'
    },
    demo () {
      return this.$config.instance === 'demo'
    },
    admin () {
      return this.$auth?.info?.adminUser
    }
  },
  methods: {
    ...mapActions([
      'toggleMenu',
      'toggleSearch',
      'toggleMobile'
    ])
  }
}
</script>
