<template>
  <div>
    <Transition name="fade">
      <div v-show="show" ref="snow" class="fixed left-0 top-0 w-screen h-screen bg-gray-900/10 dark:bg-transparent z-80000 pointer-events-none" />
    </Transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
 
export default {
  data () {
    return {
      show: false,
      snowflakesCount: 200,
      baseCSS: ``,
      bodyHeightPx: null,
      pageHeightVh: null
    }
  },
  computed: {
    ...mapGetters({
      snow: 'snow'
    })
  },
  watch: {
    snow: {
      immediate: true,
      handler (value) {
        if (value) {
          this.createSnow()
        } else {
          this.showSnow(false)
        }
      }
    }
  },
  methods: {
    setHeightVariables() {
      this.bodyHeightPx = document.body.offsetHeight;
      this.pageHeightVh = (100 * this.bodyHeightPx / window.innerHeight);
    },
    showSnow(value) {
      if (value) {
        this.show = value;
      } else {
        this.show = false
      }
    },
    generateSnow(snowDensity = 200) {
      snowDensity -= 1;
      const snowWrapper = this.$refs.snow;
      snowWrapper.innerHTML = '';
      for (let i = 0; i < snowDensity; i++) {
        let board = document.createElement('div');
        board.className = "snowflake";
        snowWrapper.appendChild(board);
      }
    },
    getOrCreateCSSElement() {
      let cssElement = document.getElementById("psjs-css");
      if (cssElement) return cssElement;

      cssElement = document.createElement('style');
      cssElement.id = 'psjs-css';
      document.head.appendChild(cssElement);
      return cssElement;
    },
    addCSS(rule) {
      const cssElement = this.getOrCreateCSSElement();
      cssElement.innerHTML = rule; // safe to use innerHTML
      document.head.appendChild(cssElement);
    },
    randomInt(value = 100) {
      return Math.floor(Math.random() * value) + 1;
    },
    randomIntRange(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getRandomArbitrary(min, max) {
      return Math.random() * (max - min) + min;
    },
    generateSnowCSS(snowDensity = 200) {
      let snowflakeName = "snowflake";
      let rule = this.baseCSS;

      for (let i = 1; i < snowDensity; i++) {
        let randomX = Math.random() * 100; // vw
        let randomOffset = Math.random() * 10 // vw;
        let randomXEnd = randomX + randomOffset;
        let randomXEndYoyo = randomX + (randomOffset / 2);
        let randomYoyoTime = this.getRandomArbitrary(0.3, 0.8);
        let randomYoyoY = randomYoyoTime * this.pageHeightVh; // vh
        let randomScale = Math.random();
        let fallDuration = this.randomIntRange(10, this.pageHeightVh / 10 * 3); // s
        let fallDelay = this.randomInt(this.pageHeightVh / 10 * 3) * -1; // s
        let opacity = Math.random();

        rule += `
          .${snowflakeName}:nth-child(${i}) {
            opacity: ${opacity};
            transform: translate(${randomX}vw, -10px) scale(${randomScale});
            animation: fall-${i} ${fallDuration}s ${fallDelay}s linear infinite;
          }
          @keyframes fall-${i} {
            ${randomYoyoTime * 100}% {
              transform: translate(${randomXEnd}vw, ${randomYoyoY}vh) scale(${randomScale});
            }
            to {
              transform: translate(${randomXEndYoyo}vw, ${this.pageHeightVh}vh) scale(${randomScale});
            }
          }
        `
      }
      this.addCSS(rule);
    },
    createSnow() {
      this.setHeightVariables();
      this.generateSnowCSS(this.snowflakesCount);
      this.generateSnow(this.snowflakesCount);

      this.showSnow(true);
    }
  }
}
</script>

<style>
.snowflake {
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(white, white);
  /* Workaround for Chromium's selective color inversion */
  border-radius: 50%;
  filter: drop-shadow(0 0 10px white);
}
</style>