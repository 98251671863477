import Tool from './Tool'

export default class Eraser extends Tool {
  config = {
    size: 16
  }

  constructor (data, config) {
    super(data, config)

    this.icon = '<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="-0.75 -0.75 24 24"><defs></defs><title>eraser</title><path d="M4.9396875 17.34375 0.984375 13.385625000000001a0.9656250000000001 0.9656250000000001 0 0 1 0 -1.3621875L10.5159375 2.491875a0.9656250000000001 0.9656250000000001 0 0 1 1.3621875 0l5.1065625 5.1065625a0.9656250000000001 0.9656250000000001 0 0 1 0 1.36125L8.653125000000001 17.291249999999998" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="m4.3884375 8.619375000000002 6.4678125 6.46875" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="m0.703125 20.154375 4.21875 0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="m11.953125 20.154375 1.40625 0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="m16.171875 20.154375 1.40625 0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="m20.390625 20.154375 1.40625 0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="m7.734375 20.154375 1.40625 0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>'

    this.state = {
      drawing: false,
      snapshot: null
    }

    if (config) {
      this.config = {
        ...this.config,
        ...config
      }
    }

    this.settings = {
      size: this.config.size || 16
    }
  }

  pointerDown ({ x, y } = {}) {
    const canvas = this.canvas
    const ctx = canvas.getContext('2d')

    this.state.drawing = true
    this.mouse.x = x
    this.mouse.y = y

    ctx.beginPath()

    ctx.lineWidth = this.settings.size || 16
    ctx.strokeStyle = this.selectedColor
    ctx.fillStyle = this.selectedColor

    this.state.snapshot = ctx.getImageData(0, 0, canvas.width, canvas.height)

    this.state.drawing = true

    this.context.layers = [
      ...this.context.layers,
      {
        type: 'eraser',
        points: []
      }
    ]
  }

  pointerMove ({ x, y } = {}) {
    if (this.state.drawing) {
      const canvas = this.canvas
      const ctx = canvas.getContext('2d')

      ctx.putImageData(this.state.snapshot, 0, 0)

      ctx.globalCompositeOperation = 'destination-out'
      ctx.lineTo(x, y)
      ctx.stroke()

      const layers = this.context.layers
      
      layers[layers.length - 1].points.push([
        this.mouse.x,
        this.mouse.y
      ])

      this.context.layers = layers
    }
  }

  pointerUp () {
    this.state.drawing = false
  }

  redraw (layer) {
    const canvas = this.canvas
    const ctx = canvas.getContext('2d')

    ctx.beginPath()

    ctx.lineWidth = this.settings.size || 16

    for (const point of layer.points) {
      const [x, y] = point
      const canvas = this.canvas
      const ctx = canvas.getContext('2d')

      ctx.globalCompositeOperation = 'destination-out'
      ctx.lineTo(x, y)
      ctx.stroke()
    }
  }

  drawCursor () {
    this.cursor.lastX = this.lerp(this.cursor.lastX, this.mouse.x, 0.5)
    this.cursor.lastY = this.lerp(this.cursor.lastY, this.mouse.y, 0.5)

    const canvas = this.canvas_cursor
    const ctx = canvas.getContext('2d')
    const radius = this.settings.size || 16

    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    ctx.arc(this.cursor.lastX, this.cursor.lastY, radius / 2, 0, Math.PI * 2, false)
    ctx.fillStyle = '#fff1f2'
    ctx.fill()
    ctx.strokeStyle = '#fecdd3'
    ctx.strokeWidth = '1'
    ctx.stroke()
    ctx.closePath()
  }
}
