<template>
  <div
    class="h-full w-full flex flex-col bg-white dark:bg-gray-900"
  >
    <template v-if="ready">
      <client-only>
        <!-- App download prompt banner -->
        <banner />

        <div
          v-if="active && accounts"
          class="box-border text-base font-normal flex relative h-full overflow-hidden"
          :class="[
            $auth.theme.light.gradient && $colorMode.value === 'light' ? $auth.theme.light.gradient : `bg-primary-${$auth.theme.light.shade}`,
            $auth.theme.dark.gradient ? $auth.theme.dark.gradient : `dark:bg-gray-900`,
          ]"
        >
          <!-- Temp grants display -->

          <div v-if="grants" class="hidden fixed top-0 right-0 m-2 px-3 py-2 rounded-lg text-sm bg-gray-700 shadow text-white z-80000 group cursor-pointer">
            Grants required

            <div class="hidden group-hover:block p-3 bg-white border absolute text-gray-800 shadow rounded-lg top-0 left-1/2 translate-y-full -translate-x-1/2 w-52">
              <ul>
                <li v-for="(actions, grant) in grants" :key="grant">
                  <span class="capitalize font-semibold">{{ grant }}</span> - {{ actions.join(', ') }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Sidebar -->
          <nav-menu />

          <!-- Right -->
          <div class="flex max-w-full flex-col flex-grow transition-all duration-500 ease-in-out overflow-hidden relative z-30 ">
            <!-- Content -->
            <div class="flex-grow h-100 flex-shrink bg-gray-50 dark:bg-gray-900 sm:rounded-l-lg overflow-hidden shadow-lg dark:border-l">
              <nuxt />
            </div>

            <mobile />
          </div>

          <search />

          <update />

          <lock />

          <snow />

          <!--<messages />-->

          <panel-controller />

          <portal-target name="modal-container" multiple />
          <portal-target name="dialog-container" multiple />
        </div>

        <div v-if="admin" class="h-12 w-full bg-orange-600 dark:bg-orange-950 text-white border-t px-3 border-orange-500 dark:border-orange-900 flex items-center justify-between">
          <div class="flex items-center">
            <o-icon icon="warning" size="12" class="text-white mr-2" />
            <p class="text-sm">
              You are currently signed in as an admin
            </p>
          </div>

          <a v-if="$auth.info" :href="`https://admin.ovatu.com/location/${$auth.info.location.idLocation}`" target="_blank" class="text-sm">
            Open admin panel
          </a>
        </div>

        <div v-if="dev" class="hidden h-12 w-full bg-sky-600 dark:bg-sky-950 text-white border-t px-3 border-sky-700 dark:border-sky-900 flex items-center justify-center">
          <o-icon icon="info" size="12" class="text-white mr-2" />
          <p class="text-sm">
            You are currently viewing a development version
          </p>
        </div>
      </client-only>
    </template>

    <loading />

    <transition name="fade">
      <div v-if="mobile" class="sm:hidden fixed inset-0 h-full w-full bg-black bg-opacity-15" @click="setMobile(false)" />
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import colors from 'tailwindcss/colors'

import Cookies from 'js-cookie'

import Themes from '@/components/layout/themes'

import NavMenu from '@/components/layout/Menu'

import Search from '@/components/search/Search'
import Loading from '@/components/layout/Loading'
import Update from '@/components/layout/Update'
// import Notifications from '@/components/layout/Notifications'
import Mobile from '@/components/layout/Mobile'
import Banner from '@/components/layout/Banner'
import Messages from '@/components/layout/Messages'
import Snow from '@/components/layout/Snow'

import Lock from '@/components/auth/elements/Lock'

import PanelController from '@/components/panel/PanelController'

import theme from '@/mixins/layout/theme'

import {
  OInternalLink
} from '@/components/elements'


export default {
  components: {
    NavMenu,
    Search,
    Loading,
    Update,
    Messages,
    Snow,
    // Notifications,
    Mobile,
    Banner,
    Lock,
    PanelController,
    OInternalLink
  },
  mixins: [theme],
  computed: {
    ...mapGetters({
      active: 'auth/active',
      accounts: 'auth/accounts',
      ready: 'auth/ready',
      mobile: 'mobile'
    }),
    grants () {
      const route = this.$route
      const grants = route.meta?.grants

      return grants
    },
    dev () {
      return this.$config.instance === 'dev'
    },
    admin () {
      return this.$auth?.info?.adminUser
    }
  },
  mounted () {
    this.setWidth(window.innerWidth)

    window.addEventListener('resize', () => {
      this.setWidth(window.innerWidth)
    })

    this.notifications()
  },
  methods:{
    ...mapMutations([
      'setWidth',
      'setMobile'
    ]),
    notifications () {
      const notifications = this.$auth.info?.panels || []

      for (const notification of notifications) {
        const options = {
          title: notification.title,
          text: notification.content,
          variant: notification.type,
          duration: 10000,
          width: 'w-200'
        }

        if (notification.action) {
          let bg = 'bg-primary'

          switch (notification.type) {
            case 'success':
              bg = 'bg-green-500'
              break
            case 'danger':
              bg = 'bg-red-500'
              break
            case 'warning':
              bg = 'bg-yellow-500'
              break
            case 'info':
              bg = 'bg-blue-500'
              break
            default:
              bg = 'bg-gray-800'
              break
          }

          if (notification.action.internalUrl) {
            options.action = {
              click: () => {
                const match = this.$redirect?.match(notification.action.internalUrl)
                if (match.component === 'a') {
                  window.location = match.path
                } else {
                  this.$router.push(match.path)
                }
              },
              label: notification.action.title
            }
          } else if (notification.action.url) {
            options.action = {
              click: () => { window.location = notification.action.url },
              label: notification.action.title
            }
          }
        }

        this.$notification.toast(options)
      }
    }
  }
}
</script>

