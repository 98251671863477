import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import Customer from './customer'
import Order from './order'
import Reservation from './reservation'
import Waitlist from './waitlist'

export default class CustomerMessage extends Model {
  static modelName () {
    return 'customerMessage'
  }

  objectID () {
    return this.idCustomerMessage
  }

  relations () {
    return {
      customer: { type: Customer },
      sender: { type: CustomerMessageSender },
      data: { type: CustomerMessageData },
      type: { type: CustomerMessageType },
      channelEmail: { type: CustomerMessageChannelStatus },
      channelSms: { type: CustomerMessageChannelStatus },
      attachments: { type: CustomerMessageAttachment }
    }
  }

  static counts () {
    const url = this.modelBaseURL() + '/counts'
    return this.requestData(Request.get(url))
  }

  static customer (customerID, { markAsRead = true, page = 1, limit = 50 } = {}) {
    const url = this.modelBaseURL() + `/customer?page=${page}&limit=${limit}&markAsRead=${markAsRead ? 1 : 0}`

    const data = {
      customer: customerID
    }

    return this.requestList(Request.post(url, JSON.stringify(data)), this)
  }

  static send ({
    idCustomer,
    subject,
    body,
    attachments,
    sender = CustomerMessageSender.location,
    emailSend,
    smsSend
  } = {}, uploads = {}) {
    const formData = new FormData()

    const data = {
      idCustomer,
      subject,
      body,
      attachments,
      sender,
      emailSend,
      smsSend
    }

    formData.append('_request', JSON.stringify(data))
    formData.append('_method', 'POST')

    for (const key in uploads) {
      formData.append(key, uploads[key])
    }

    const url = this.modelBaseURL()
    const request = Request.jsonPost(url, formData)

    return this.requestItem(request, this)
  }

  get isSenderLocation () {
    return this.sender === CustomerMessageSender.location
  }

  get isSenderCustomer () {
    return this.sender === CustomerMessageSender.customer
  }

  get isSenderSystem () {
    return this.sender === CustomerMessageSender.system
  }

  get isSenderEmployee () {
    return this.sender === CustomerMessageSender.employee
  }

  get isChannelEmailDoNotSend () {
    return this.channelEmail === CustomerMessageChannelStatus.doNotSend
  }

  get isChannelEmailSend () {
    return this.channelEmail === CustomerMessageChannelStatus.send
  }

  get isChannelEmailSent () {
    return this.channelEmail === CustomerMessageChannelStatus.sent
  }

  get isChannelEmailFailed () {
    return this.channelEmail === CustomerMessageChannelStatus.failed
  }

  get isChannelEmailOpened () {
    return this.channelEmail === CustomerMessageChannelStatus.opened
  }

  get isChannelEmailOrigin () {
    return this.channelEmail === CustomerMessageChannelStatus.origin
  }

  get isChannelSMSDoNotSend () {
    return this.channelSms === CustomerMessageChannelStatus.doNotSend
  }

  get isChannelSMSSend () {
    return this.channelSms === CustomerMessageChannelStatus.send
  }

  get isChannelSMSSent () {
    return this.channelSms === CustomerMessageChannelStatus.sent
  }

  get isChannelSMSFailed () {
    return this.channelSms === CustomerMessageChannelStatus.failed
  }

  get isChannelSMSOpened () {
    return this.channelSms === CustomerMessageChannelStatus.opened
  }

  get isChannelSMSOrigin () {
    return this.channelSms === CustomerMessageChannelStatus.origin
  }

  get isTypeNewReservation () {
    return this.type === CustomerMessageType.newReservation
  }

  get isTypeNewReservationConfirmed () {
    return this.type === CustomerMessageType.newReservationConfirmed
  }

  get isTypeNewReservationRejected () {
    return this.type === CustomerMessageType.newReservationRejected
  }

  get isTypeNewReservationCancelled () {
    return this.type === CustomerMessageType.newReservationCancelled
  }

  get isTypeNewReservationReminder () {
    return this.type === CustomerMessageType.newReservationReminder
  }

  get isTypeReservationMoved () {
    return this.type === CustomerMessageType.reservationMoved
  }

  get isTypeReservationCancelled () {
    return this.type === CustomerMessageType.reservationCancelled
  }

  get isTypeReservationReminder () {
    return this.type === CustomerMessageType.reservationReminder
  }

  get isTypeNewSale () {
    return this.type === CustomerMessageType.newSale
  }

  get isTypePasswordReset () {
    return this.type === CustomerMessageType.passwordReset
  }

  get isTypeReceivedCampaign () {
    return this.type === CustomerMessageType.receivedCampaign
  }

  get isTypeGeneral () {
    return this.type === CustomerMessageType.general
  }

  get isTypeLoginCode () {
    return this.type === CustomerMessageType.loginCode
  }

  get isTypeNewCustomer () {
    return this.type === CustomerMessageType.newCustomer
  }

  get isTypeNewOrder () {
    return this.type === CustomerMessageType.newOrder
  }

  get isStatusQueued () {
    return this.status === CustomerMessageStatus.queued
  }

  get isStatusSending () {
    return this.status === CustomerMessageStatus.sending
  }

  get isStatusSent () {
    return this.status === CustomerMessageStatus.sent
  }

  get isStatusFailed () {
    return this.status === CustomerMessageStatus.failed
  }
}

export class CustomerMessageData extends APIObject {
  static modelName () {
    return 'customerMessageData'
  }

  relations () {
    return {
      customer: { type: Customer },
      order: { type: Order },
      reservation: { type: Reservation },
      waitlist: { type: Waitlist }
    }
  }
}


export class CustomerMessageAttachment extends APIObject {
  static modelName () {
    return 'customerMessageAttachment'
  }

  relations () {
    return {
      type: { type: CustomerMessageAttachmentType }
    }
  }

  get isTypeReservation () {
    return this.type === CustomerMessageAttachmentType.reservation
  }

  get isTypeOrder () {
    return this.type === CustomerMessageAttachmentType.order
  }

  get isTypeCustomerForm () {
    return this.type === CustomerMessageAttachmentType.customerForm
  }

  get isTypeUpload () {
    return this.type === CustomerMessageAttachmentType.upload
  }

  get isTypeCustomerCourse () {
    return this.type === CustomerMessageAttachmentType.customerCourse
  }

  get isTypeGiftCard () {
    return this.type === CustomerMessageAttachmentType.giftCard
  }

  get isTypeCustomerPass () {
    return this.type === CustomerMessageAttachmentType.customerPass
  }

  get isTypeWaitlist () {
    return this.type === CustomerMessageAttachmentType.waitlist
  }

  get isTypeCampaign () {
    return this.type === CustomerMessageAttachmentType.campaign
  }
}

export const CustomerMessageAttachmentType = new Enum({
  reservation: { value: 1, description: 'Reservation' },
  order: { value: 2, description: 'Order' },
  customerForm: { value: 3, description: 'Customer form' },
  upload: { value: 4, description: 'Upload' },
  customerCourse: { value: 5, description: 'Customer course' },
  giftCard: { value: 6, description: 'Gift card' },
  customerPass: { value: 7, description: 'Customer pass' },
  waitlist: { value: 8, description: 'Waitlist' },
  campaign: { value: 9, description: 'Campaign' }
})

export const CustomerMessageStatus = new Enum({
  queued: { value: 1, description: 'Queued' },
  sending: { value: 2, description: 'Sending' },
  sent: { value: 3, description: 'Sent' },
  failed: { value: 4, description: 'Failed' }
})

export const CustomerMessageSender = new Enum({
  location: { value: 1, description: 'Location' },
  customer: { value: 2, description: 'Customer' },
  system: { value: 3, description: 'System' },
  employee: { value: 4, description: 'Employee' }
})

export const CustomerMessageChannelStatus = new Enum({
  doNotSend: { value: 1, description: 'Do not send' },
  send: { value: 2, description: 'Send' },
  sent: { value: 3, description: 'Sent' },
  failed: { value: 4, description: 'Failed' },
  opened: { value: 5, description: 'Opened' },
  origin: { value: 6, description: 'Origin' }
})

export const CustomerMessageType = new Enum({
  newReservation: { value: 1, description: 'New reservation' },
  newReservationConfirmed: { value: 2, description: 'New reservation confirmed' },
  newReservationRejected: { value: 3, description: 'New reservation rejected' },
  newReservationCancelled: { value: 4, description: 'New reservation cancelled' },
  newReservationReminder: { value: 5, description: 'New reservation reminder' },
  reservationMoved: { value: 6, description: 'Reservation moved' },
  reservationCancelled: { value: 7, description: 'Reservation cancelled' },
  reservationReminder: { value: 8, description: 'Reservation reminder' },
  newSale: { value: 9, description: 'New sale' },
  passwordReset: { value: 10, description: 'Password reset' },
  receivedCampaign: { value: 11, description: 'Received campaign' },
  general: { value: 12, description: 'General' },
  loginCode: { value: 13, description: 'Login code' },
  newCustomer: { value: 14, description: 'New customer' },
  newOrder: { value: 15, description: 'New order' }
})
