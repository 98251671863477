<template>
  <ValidationProvider ref="validation" :rules="rules" :name="name">
    <v-date-picker v-if="date" ref="calendar" class="hidden" :value="null" />
    <div
      :class="{
        'flex items-start space-x-3' : col,
        'flex-row-reverse justify-end space-x-3 space-x-reverse' : col & reverse,
      }"
    >
      <div
        :class="{
          // col && !reverse ? 'w-3/12' : 'w-full'
          'w-full' : !col,
          'w-3/12' : col && !reverse,
        }"
      >
        <div class="w-full flex justify-between items-center">
          <label v-if="label" :class="labelClass" tabindex="-1">
            <span v-html="label" />
            <o-tooltip v-if="isRequired" text="Required field" class="inline-block">
              <span class="text-sm text-red-400 dark:text-red-600">
                *
              </span>
            </o-tooltip>

            <o-tooltip v-if="tooltip" :text="tooltip" class="inline-block translate-y-[0.2rem]">
              <div class="h-5 w-5 rounded-full text-blue-500 flex items-center justify-center">
                <o-icon icon="info" />
              </div>
            </o-tooltip>
          </label>
          <slot name="control">
            <o-tooltip v-if="modified" :text="modifiedMessage">
              <o-icon icon="warningBold" fill class="text-yellow-600 mx-1 dark:text-yellow-400" />
            </o-tooltip>
          </slot>
        </div>
        <p v-if="info" :class="infoClass" v-html="info" />
      </div>
      <div
        :class="{
          // col && !reverse ? 'w-3/12' : 'w-full'
          'w-full' : !col,
          'w-9/12' : col && !reverse,
        }"
      >
        <div ref="fields" :class="[{ 'mt-1' : label || info }]">
          <slot v-bind="slotProps" />
        </div>
        <div v-if="message || (isError && errorMessage) || (isSuccess && successMessage)" :class="statusClasses">
          <o-icon :icon="icon" :size="14" class="ml-1 mr-2" />
          <p class="leading-none pt-px" v-html="errorMessage || message || successMessage" />
        </div>
        <div v-else-if="date && dateFormat">
          <p class="text-sm opacity-80 mt-1">
            {{ dateFormat }}
          </p>
        </div>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'OFieldGroup',
  components: {
    ValidationProvider
  },
  props: {
    label: {
      type: String,
      default: null
    },
    info: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    message: {
      type: String,
      default: null
    },
    successMessage: {
      type: String,
      default: null
    },
    col: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    showSuccess: {
      type: Boolean,
      default: false
    },
    status: {
      type: [Boolean, String],
      default: null
    },
    modified: {
      type: Boolean,
      default: false
    },
    modifiedMessage: {
      type: String,
      default: 'This field has been modified from its original value'
    },
    labelClass: {
      type: String,
      default: 'block text-sm font-medium float-left'
    },
    infoClass: {
      type: String,
      default: 'block opacity-80 text-sm'
    },
    statusClass: {
      type: String,
      default: 'flex items-center text-gray-600 text-sm pt-2 pb-1 -ml-2px leading-none opacity-85 dark:text-gray-300'
    },
    errorClass: {
      type: String,
      default: 'text-red-500'
    },
    successClass: {
      type: String,
      default: 'text-green-500'
    },
    warningClass: {
      type: String,
      default: 'text-orange-400 dark:text-orange-600'
    },
    date: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ValidationProvider: null,
      dateFormat: ''
    }
  },
  computed: {
    errorMessage () {
      const ValidationProvider = this.ValidationProvider
      return ValidationProvider ? ValidationProvider.errors[0] : false
    },
    statusClasses () {
      const classes = [this.statusClass]

      if (this.isError && this.errorClass) {
        classes.push(this.errorClass)
      } else if (this.isSuccess && this.successClass) {
        classes.push(this.successClass)
      } else if (this.isWarning && this.warningClass) {
        classes.push(this.warningClass)
      }

      return classes
    },
    isSuccess () {
      return (this.status === true || this.status === 'success' || (this.ValidationProvider && this.ValidationProvider.flags.passed)) && this.showSuccess
    },
    isError () {
      return this.status === false || this.status === 'error' || (this.ValidationProvider && this.ValidationProvider.flags.failed)
    },
    isWarning () {
      return this.status === 'warning'
    },
    isRulesString () {
      return typeof this.rules === 'string'
    },
    isRequired () {
      if (!this.rules) {
        return false
      }

      const rules = this.isRulesString ? this.rules : Object.keys(this.rules)

      return rules.includes('required')
    },
    icon () {
      if (this.isError) {
        return 'danger'
      }

      if (this.isSuccess) {
        return 'success'
      }

      if (this.isWarning) {
        return 'warning'
      }

      return 'info'
    },
    slotProps () {
      let status

      if (this.isSuccess) {
        status = 'success'
      }

      if (this.isWarning) {
        status = 'warning'
      }

      if (this.isError) {
        status = 'error'
      }

      return {
        status
      }
    }
  },
  mounted () {
    this.ValidationProvider = this.$refs.validation

    if (this.date) {
      this.dateFormat = this.$refs.calendar?.$locale.masks.L
    }
  }
}
</script>
