import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_a913e632 from 'nuxt_plugin_sentryserver_a913e632' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_45bc9b5f from 'nuxt_plugin_sentryclient_45bc9b5f' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_pluginsplugina7b61e20_d4565fe0 from 'nuxt_plugin_pluginsplugina7b61e20_d4565fe0' // Source: ./plugins.plugin.a7b61e20.js (mode: 'all')
import nuxt_plugin_pluginutils_47598f10 from 'nuxt_plugin_pluginutils_47598f10' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_6b30f07a from 'nuxt_plugin_pluginrouting_6b30f07a' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_1e75328c from 'nuxt_plugin_pluginmain_1e75328c' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_7687cc6a from 'nuxt_plugin_cookieuniversalnuxt_7687cc6a' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginserver_5420358e from 'nuxt_plugin_pluginserver_5420358e' // Source: ./color-mode/plugin.server.js (mode: 'server')
import nuxt_plugin_pluginclient_703673b1 from 'nuxt_plugin_pluginclient_703673b1' // Source: ./color-mode/plugin.client.js (mode: 'client')
import nuxt_plugin_datefns_177dfa6e from 'nuxt_plugin_datefns_177dfa6e' // Source: ./date-fns.js (mode: 'all')
import nuxt_plugin_portalvue_5fb12be0 from 'nuxt_plugin_portalvue_5fb12be0' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_components_50cb0b6b from 'nuxt_plugin_components_50cb0b6b' // Source: ../plugins/components.js (mode: 'all')
import nuxt_plugin_eventbus_3db4fd4a from 'nuxt_plugin_eventbus_3db4fd4a' // Source: ../plugins/eventbus.js (mode: 'all')
import nuxt_plugin_centrifuge_5f671275 from 'nuxt_plugin_centrifuge_5f671275' // Source: ../plugins/centrifuge.js (mode: 'all')
import nuxt_plugin_print_a4dc9cc0 from 'nuxt_plugin_print_a4dc9cc0' // Source: ../plugins/print.js (mode: 'all')
import nuxt_plugin_export_ee03f9e6 from 'nuxt_plugin_export_ee03f9e6' // Source: ../plugins/export.js (mode: 'all')
import nuxt_plugin_cookies_2c3cf8fe from 'nuxt_plugin_cookies_2c3cf8fe' // Source: ../plugins/cookies.js (mode: 'all')
import nuxt_plugin_active_2825cb00 from 'nuxt_plugin_active_2825cb00' // Source: ../plugins/auth/active.js (mode: 'all')
import nuxt_plugin_index_78cdc694 from 'nuxt_plugin_index_78cdc694' // Source: ../plugins/auth/index.js (mode: 'all')
import nuxt_plugin_grants_d1d54de2 from 'nuxt_plugin_grants_d1d54de2' // Source: ../plugins/auth/grants.js (mode: 'all')
import nuxt_plugin_features_5ff303ae from 'nuxt_plugin_features_5ff303ae' // Source: ../plugins/auth/features.js (mode: 'all')
import nuxt_plugin_gates_5f7104c0 from 'nuxt_plugin_gates_5f7104c0' // Source: ../plugins/auth/gates.js (mode: 'all')
import nuxt_plugin_date_3cd5b753 from 'nuxt_plugin_date_3cd5b753' // Source: ../plugins/date.js (mode: 'all')
import nuxt_plugin_currency_37da79e0 from 'nuxt_plugin_currency_37da79e0' // Source: ../plugins/currency.js (mode: 'all')
import nuxt_plugin_redirect_368ca365 from 'nuxt_plugin_redirect_368ca365' // Source: ../plugins/redirect.js (mode: 'all')
import nuxt_plugin_panel_324d86e9 from 'nuxt_plugin_panel_324d86e9' // Source: ../plugins/panel.js (mode: 'all')
import nuxt_plugin_dialog_80887e0e from 'nuxt_plugin_dialog_80887e0e' // Source: ../plugins/dialog.js (mode: 'all')
import nuxt_plugin_modal_640f2cc0 from 'nuxt_plugin_modal_640f2cc0' // Source: ../plugins/modal.js (mode: 'all')
import nuxt_plugin_mobile_d5f19142 from 'nuxt_plugin_mobile_d5f19142' // Source: ../plugins/mobile.js (mode: 'all')
import nuxt_plugin_app_784ba9a8 from 'nuxt_plugin_app_784ba9a8' // Source: ../plugins/app.js (mode: 'all')
import nuxt_plugin_veevalidate_34294c84 from 'nuxt_plugin_veevalidate_34294c84' // Source: ../plugins/packages/vee-validate.js (mode: 'all')
import nuxt_plugin_gtag_6f873798 from 'nuxt_plugin_gtag_6f873798' // Source: ../plugins/packages/gtag.js (mode: 'all')
import nuxt_plugin_idle_82bf8412 from 'nuxt_plugin_idle_82bf8412' // Source: ../plugins/packages/idle.js (mode: 'client')
import nuxt_plugin_shortkey_158185f0 from 'nuxt_plugin_shortkey_158185f0' // Source: ../plugins/packages/shortkey.js (mode: 'client')
import nuxt_plugin_vcalendar_1e0c5476 from 'nuxt_plugin_vcalendar_1e0c5476' // Source: ../plugins/packages/v-calendar.js (mode: 'client')
import nuxt_plugin_accounts_1a1f19e0 from 'nuxt_plugin_accounts_1a1f19e0' // Source: ../plugins/auth/accounts.js (mode: 'client')
import nuxt_plugin_imperaviarticle_6a4a25e7 from 'nuxt_plugin_imperaviarticle_6a4a25e7' // Source: ../plugins/packages/imperavi-article.js (mode: 'client')
import nuxt_plugin_redactor_69963c72 from 'nuxt_plugin_redactor_69963c72' // Source: ../plugins/packages/redactor.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Ovatu Manager","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, shrink-to-fit=no"},{"name":"mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"msapplication-starturl","content":"\u002F"},{"name":"apple-itunes-app","content":"app-id=1589890786"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"manifest","href":"\u002Fmanifest.json"}],"script":[{"src":"\u002Fhelpscout.js","body":true,"async":true}],"bodyAttrs":{"class":"antialiased font-sans text-gray-800 dark:text-gray-100 bg-white dark:bg-gray-900"},"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_a913e632 === 'function') {
    await nuxt_plugin_sentryserver_a913e632(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_45bc9b5f === 'function') {
    await nuxt_plugin_sentryclient_45bc9b5f(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsplugina7b61e20_d4565fe0 === 'function') {
    await nuxt_plugin_pluginsplugina7b61e20_d4565fe0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_47598f10 === 'function') {
    await nuxt_plugin_pluginutils_47598f10(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_6b30f07a === 'function') {
    await nuxt_plugin_pluginrouting_6b30f07a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_1e75328c === 'function') {
    await nuxt_plugin_pluginmain_1e75328c(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_7687cc6a === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_7687cc6a(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_5420358e === 'function') {
    await nuxt_plugin_pluginserver_5420358e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_703673b1 === 'function') {
    await nuxt_plugin_pluginclient_703673b1(app.context, inject)
  }

  if (typeof nuxt_plugin_datefns_177dfa6e === 'function') {
    await nuxt_plugin_datefns_177dfa6e(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_5fb12be0 === 'function') {
    await nuxt_plugin_portalvue_5fb12be0(app.context, inject)
  }

  if (typeof nuxt_plugin_components_50cb0b6b === 'function') {
    await nuxt_plugin_components_50cb0b6b(app.context, inject)
  }

  if (typeof nuxt_plugin_eventbus_3db4fd4a === 'function') {
    await nuxt_plugin_eventbus_3db4fd4a(app.context, inject)
  }

  if (typeof nuxt_plugin_centrifuge_5f671275 === 'function') {
    await nuxt_plugin_centrifuge_5f671275(app.context, inject)
  }

  if (typeof nuxt_plugin_print_a4dc9cc0 === 'function') {
    await nuxt_plugin_print_a4dc9cc0(app.context, inject)
  }

  if (typeof nuxt_plugin_export_ee03f9e6 === 'function') {
    await nuxt_plugin_export_ee03f9e6(app.context, inject)
  }

  if (typeof nuxt_plugin_cookies_2c3cf8fe === 'function') {
    await nuxt_plugin_cookies_2c3cf8fe(app.context, inject)
  }

  if (typeof nuxt_plugin_active_2825cb00 === 'function') {
    await nuxt_plugin_active_2825cb00(app.context, inject)
  }

  if (typeof nuxt_plugin_index_78cdc694 === 'function') {
    await nuxt_plugin_index_78cdc694(app.context, inject)
  }

  if (typeof nuxt_plugin_grants_d1d54de2 === 'function') {
    await nuxt_plugin_grants_d1d54de2(app.context, inject)
  }

  if (typeof nuxt_plugin_features_5ff303ae === 'function') {
    await nuxt_plugin_features_5ff303ae(app.context, inject)
  }

  if (typeof nuxt_plugin_gates_5f7104c0 === 'function') {
    await nuxt_plugin_gates_5f7104c0(app.context, inject)
  }

  if (typeof nuxt_plugin_date_3cd5b753 === 'function') {
    await nuxt_plugin_date_3cd5b753(app.context, inject)
  }

  if (typeof nuxt_plugin_currency_37da79e0 === 'function') {
    await nuxt_plugin_currency_37da79e0(app.context, inject)
  }

  if (typeof nuxt_plugin_redirect_368ca365 === 'function') {
    await nuxt_plugin_redirect_368ca365(app.context, inject)
  }

  if (typeof nuxt_plugin_panel_324d86e9 === 'function') {
    await nuxt_plugin_panel_324d86e9(app.context, inject)
  }

  if (typeof nuxt_plugin_dialog_80887e0e === 'function') {
    await nuxt_plugin_dialog_80887e0e(app.context, inject)
  }

  if (typeof nuxt_plugin_modal_640f2cc0 === 'function') {
    await nuxt_plugin_modal_640f2cc0(app.context, inject)
  }

  if (typeof nuxt_plugin_mobile_d5f19142 === 'function') {
    await nuxt_plugin_mobile_d5f19142(app.context, inject)
  }

  if (typeof nuxt_plugin_app_784ba9a8 === 'function') {
    await nuxt_plugin_app_784ba9a8(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_34294c84 === 'function') {
    await nuxt_plugin_veevalidate_34294c84(app.context, inject)
  }

  if (typeof nuxt_plugin_gtag_6f873798 === 'function') {
    await nuxt_plugin_gtag_6f873798(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_idle_82bf8412 === 'function') {
    await nuxt_plugin_idle_82bf8412(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_shortkey_158185f0 === 'function') {
    await nuxt_plugin_shortkey_158185f0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vcalendar_1e0c5476 === 'function') {
    await nuxt_plugin_vcalendar_1e0c5476(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_accounts_1a1f19e0 === 'function') {
    await nuxt_plugin_accounts_1a1f19e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_imperaviarticle_6a4a25e7 === 'function') {
    await nuxt_plugin_imperaviarticle_6a4a25e7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_redactor_69963c72 === 'function') {
    await nuxt_plugin_redactor_69963c72(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
