<template>
  <a :href="result.data.link" target="_blank" rel="noopener noreferrer" class="flex items-center leading-none py-1 text-gray-800 dark:text-gray-100">
    <o-icon :icon="`hg-${result.data.icon}`" class="mr-4" />
    <div>
      <p class="my-1">{{ result.data.label }}</p>
      <p class="text-gray-600 text-sm dark:text-gray-300">{{ result.data.info }}</p>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    result: {
      type: Object,
      required: true
    }
  }
}
</script>
