var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    `relative bg-primary-900 dark:bg-gray-900 h-screen w-screen sm:overflow-hidden text-gray-900 dark:text-white`,
    _vm.$auth.theme.light.gradient ? `${_vm.$auth.theme.light.gradient} dark:bg-gray-900` : `bg-primary-900 dark:bg-gray-900`,
  ]},[_c('div',{class:[
      'inset-0 h-full w-full bg-cover bg-center absolute bg-no-repeat',
      _vm.$auth.theme.light.gradient ? `bg-black/5 dark:bg-gray-950/10` : `bg-primary-950/20 dark:bg-gray-950/10`,
    ],style:(_vm.mask)}),_vm._v(" "),(!_vm.slug || _vm.location)?_c('router-view',{attrs:{"location":_vm.location}}):_vm._e(),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"h-screen w-screen fixed inset-0 bg-gray-50 flex items-center z-80000"},[_c('div',{staticClass:"mx-auto max-w-md px-5 text-gray-700 text-center"},[_c('div',{staticClass:"text-6xl font-dark font-bold leading-none mb-6"},[_vm._v("\n        "+_vm._s('Something went wrong')+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"text-2xl tracking-tighter font-light leading-normal"},[_vm._v("\n        "+_vm._s('Sorry, it looks like theres been error or the requested account doesn\'t exist...')+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"mb-8 mt-8"},[_vm._v("\n        Contact hello@ovatu.com if you need some help\n      ")]),_vm._v(" "),_c('o-button',{attrs:{"to":"/auth/domain","size":"lg","variant":"info"}},[_vm._v("\n        Back to safety\n      ")])],1)]):_vm._e(),_vm._v(" "),_c('loading'),_vm._v(" "),_c('portal-target',{attrs:{"name":"modal-container","multiple":""}}),_vm._v(" "),_c('portal-target',{attrs:{"name":"dialog-container","multiple":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }