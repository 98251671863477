var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full flex flex-col"},[(_vm.showTabs)?_c('div',{staticClass:"flex-shrink h-10 w-full flex items-center flex-row dark:bg-gray-900 dark:text-white",class:_vm.navClass},[_vm._t("leading"),_vm._v(" "),(_vm.showArrows)?[_c('o-button',{attrs:{"flat":"","size":"sm","icon":"chevronLeft"},on:{"click":function($event){return _vm.handleMoveScroll(-1)}}}),_vm._v(" "),_vm._m(0)]:_vm._e(),_vm._v(" "),_c('o-scroll',{ref:"scroll",attrs:{"x-axis":"","y-axis":false,"options":{ visibility: 'hidden' }}},[_c('div',{staticClass:"flex relative h-full items-center px-2"},[_vm._l((_vm.tabs),function(tab){return [(
              _vm.$gates.access(tab.path) &&
                (_vm.owner ||
                  (_vm.$grants.access(tab.path) && _vm.$features.access(tab.path)))
            )?_c('nuxt-link',{key:tab.path,ref:tab.path,refInFor:true,staticClass:"z-10 flex h-8 flex-shrink-0 items-center text-sm -mb-px leading-none hover:text-primary-600 focus:outline-none px-2 mr-2 md:mr-4",class:[
              _vm.active && tab.path === _vm.active.path
                ? 'text-primary-600 active:text-primary-600 font-medium dark:text-primary-400'
                : 'opacity-80',
              _vm.$features.access(tab.path) ? '' : 'opacity-50'
            ],attrs:{"to":tab.path}},[(tab.icon)?_c('o-icon',{staticClass:"lg:mr-3 p-2 md:p-0 hidden lg:block",attrs:{"icon":tab.icon,"size":16}}):_vm._e(),_vm._v(" "),_c('span',{},[_vm._v(_vm._s(tab.label))])],1):_vm._e()]}),_vm._v(" "),_c('span',{staticClass:"absolute transition-all ease-in-out duration-200 h-2px bottom-0 bg-primary-600 shadow'",style:(_vm.underline)})],2)]),_vm._v(" "),(_vm.showArrows)?[_vm._m(1),_vm._v(" "),_c('o-button',{attrs:{"flat":"","size":"sm","icon":"chevronRight"},on:{"click":function($event){return _vm.handleMoveScroll(1)}}})]:_vm._e(),_vm._v(" "),_vm._t("trailing")],2):_vm._e(),_vm._v(" "),_c('div',{class:_vm.containerClass},[_c('o-scroll',{staticClass:"py-3"},[(_vm.showHeader && _vm.active)?_c('div',{staticClass:"w-full flex items-center justify-between px-5 py-2"},[_c('div',[_c('h2',{staticClass:"font-medium text-gray-700"},[_vm._v("\n            "+_vm._s(_vm.active.label)+"\n          ")]),_vm._v(" "),(_vm.active.info)?_c('p',{staticClass:"text-sm text-gray-600 font-normal"},[_vm._v("\n            "+_vm._s(_vm.active.info)+"\n          ")]):_vm._e()]),_vm._v(" "),_vm._t("control")],2):_vm._e(),_vm._v(" "),_vm._t("default")],2)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2 pl-2 h-full"},[_c('span',{staticClass:"border-r dark:border-gray-800 h-full"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2 pr-2 h-full"},[_c('span',{staticClass:"border-r dark:border-gray-800 h-full"})])
}]

export { render, staticRenderFns }