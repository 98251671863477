import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

const ls = new SecureLS({ isCompression: false })

export default ({ store, req, $auth }) => {
  createPersistedState({
    key: 'auth',
    paths: [
      'auth.accounts',
      'hideBanner',
      'hideChristmasBanner',
    ],
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    },
    rehydrated: (store) => {
      $auth.syncActive({ lock: true })
    }
  })(store)
}
