<template>
  <div class="flex flex-col">
    <div v-if="search" class="w-full border-b dark:border-gray-800 relative">
      <text-field v-model="query" placeholder="Filter" leading="search" border="" shadow="" />
      <div class="absolute right-0 transform mr-2 top-1/2 -translate-y-1/2">
        <o-button
          v-if="query.length"
          size="xs"
          variant="danger"
          transparent
          flat
          icon="x"
          @click="clearQuery()"
        />
      </div>
    </div>

    <div class="flex-shrink flex-grow h-1/2 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5">
      <o-scroll class="pb-2 max-h-80">
        <div
          v-if="selectAll"
          :class="selectAllClass"
        >
          <o-checkbox
            v-model="selectAllOptions"
            :name="name"
            :required="required"
            :disabled="disabled"
            :status="status"
            :size="size"
            :label="selectAllLabel"
            :base-class="checkboxBaseClass"
            :option-class="optionClass"
            class="mb-2"
          />
        </div>

        <div class="my-2 px-2">
          <div
            v-for="(item, index) in filtered"
            :key="`${ id || name }-${index}`"
            :class="item.class"
          >
            <o-checkbox
              :id="`${ id || name || key }-${index}`"
              :key="`${ id || name }-${index}`"
              v-model="currentValue"
              :value="item.value"
              :name="name"
              :required="required"
              :disabled="disabled"
              :status="status"
              :size="size"
              :label="item.text || item.label"
              :info="item.info"
              :control="item.control"
              :base-class="checkboxBaseClass"
              :option-class="optionClass"
              @input="onInput"
              @blur="onBlur"
              @focus="onFocus"
            >
              <slot name="option" :option="item" />
            </o-checkbox>
          </div>
        </div>
      </o-scroll>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

import OCheckbox from './Checkbox'
import TextField from './Text'
import multi from '@//mixins/fields/multi.js'
import classes from '@//mixins/fields/classes.js'

export default {
  components: {
    OCheckbox,
    TextField
  },
  mixins: [multi, classes],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    value: {
      type: [Array, Object, String, Number],
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'flex flex-col'
    },
    checkboxBaseClass: {
      type: [String, Object, Array],
      default: undefined
    },
    defaultStatusClass: {
      type: [String, Object, Array],
      default: 'bg-transparent'
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'border-yellow-400 bg-yellow-100'
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'border-red-300 bg-red-100 dark:bg-red-400/20'
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'border-green-300 bg-green-100'
    },
    disabledClass: {
      type: [String, Object, Array],
      default: 'cursor-not-allowed opacity-75'
    },
    defaultSizeClass: {
      type: [String, Object, Array],
      default: ''
    },
    largeSizeClass: {
      type: [String, Object, Array],
      default: 'text-lg'
    },
    smallSizeClass: {
      type: [String, Object, Array],
      default: 'text-sm'
    },
    optionClass: {
      type: [String, Object, Array],
      default: 'py-1 px-2 mb-1 flex items-center rounded transition duration-200 cursor-pointer bg-gray-50 dark:bg-gray-900'
    },
    inputClass: {
      type: [String, Object, Array],
      default: ''
    },
    labelClass: {
      type: [String, Object, Array],
      default: 'w-full flex justify-between items-center leading-none'
    },
    imageClass: {
      type: [String, Object, Array],
      default: 'h-8 w-8 rounded-full'
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    selectAllLabel: {
      type: String,
      default: 'Select all'
    },
    selectAllClass: {
      type: [String, Object, Array],
      default: 'my-2 px-2 border-b dark:border-gray-800 font-semibold'
    }
  },
  data () {
    return {
      key: `${Date.now()}-${Math.random()}`,
      currentValue: this.value,
      query: ''
    }
  },
  computed: {
    filtered () {
      const items = this.normalizedOptions

      if (this.query.length) {
        const fuse = new Fuse(items, {
          keys: [
            'text',
            'info',
            'value'
          ]
        })
        const results = fuse.search(this.query)
        return results.map(({ item }) => item)
      }

      return items
    },
    optionValues () {
      return this.normalizedOptions
        .map(option => JSON.stringify(option))
    },
    selectAllOptions: {
      get () {
        const value = this.currentValue
        const options = this.normalizedOptions

        return !!value.length &&
          !!options.every(function (v) {
            return value.includes(v.value)
          })
      },
      set (value) {
        if (!value) {
          const options = this.normalizedOptions.map(o => o.value)
          this.currentValue = this.currentValue.filter(v => !options.includes(v))
        } else {
          const options = this.normalizedOptions.map(o => o.value)
          this.currentValue = [...new Set([...this.currentValue, ...options])]
        }
      }
    }
  },
  watch: {
    value (value) {
      this.currentValue = value
    },
    currentValue (currentValue) {
      this.$emit('input', currentValue)
      this.$emit('change', currentValue)
    }
  },
  methods: {
    clearQuery () {
      this.query = ''
    },
    onInput (value) {
      this.currentValue = value
    },
    onBlur (e) {
      this.$emit('blur', e)
    },
    onFocus (e) {
      this.$emit('focus', e)
    }
  }
}
</script>
