<template>
  <li class="my-px">
    <button
      class="w-full flex flex-row flex-grow items-center h-8 rounded-lg  relative hover:bg-white/10 transition-colors"
      :class="[
        snow ? `bg-white/10 dark:bg-primary-${$auth.theme.dark.shade} dark:text-white/80` : '',
        collapse ? 'px-2 text-white/90 dark:text-white/90' : 'px-3 text-white/70 dark:text-white/70',
      ]"
      @click="toggleSnow"
    >
      <div class="w-full flex items-center">
        <span v-if="!collapse && snow" class="block h-full w-3 left-0 absolute p-1">
          <span class="block w-full h-full bg-primary rounded-full" />
        </span>

        <span
          class="flex items-center justify-center text-xl "
          :class="[
            collapse ? '-ml-px' : ''
          ]"
        >
          <span v-if="!snow">
            ❄️
          </span>

          <span v-else>
            🥶
          </span>
        </span>
        <span class="ml-4 text-base" :class="[ collapse? 'sm:hidden' : '']">
          <span v-if="!snow">
            Let it snow!
          </span>

          <span v-else>
            I'm getting cold!
          </span>
        </span>
      </div>
    </button>
  </li>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      collapse: 'menu',
      snow: 'snow'
    })
  },
  methods: {
    ...mapActions({
      toggleSnow: 'toggleSnow'
    })
  }
}

</script>
